@font-face {
  font-family: "Karla";
  font-weight: 400;
  src: url("../assets/fonts/karla/Karla-Regular.ttf");
}

@font-face {
  font-family: "Karla";
  font-weight: 100;
  src: url("../assets/fonts/karla/Karla-ExtraLight.ttf");
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("../assets/fonts/lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-weight: 200;
  src: url("../assets/fonts/lato/Lato-Light.ttf");
}

@font-face {
  font-family: "Lekton";
  font-weight: 400;
  src: url("../assets/fonts/lekton/Lekton-Regular.ttf");
}

@font-face {
  font-family: "Lekton";
  font-weight: 700;
  src: url("../assets/fonts/lekton/Lekton-Bold.ttf");
}


.c-contact {
    height: 640px;
    animation: 0.5s ease 0s 1 fadeIn;

    &-body {
        width: 100%;
    }

    &-title {
        text-align: left;
        margin-bottom: 2rem;
    }

    &-subtitle {
        margin-bottom: 1rem;
    }

    &-form-inputs {
        display: flex;
        flex-wrap: wrap;

        &-left {
            width: 100%;
            margin-right: 0;
        }

        &-right {
            width: 100%;
            margin-left: 0;
        }
    }

    &-person-name {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &-button-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &-error-text {
        margin-top: 1rem;
    }
}

div.c-contact-appointments {
    margin-bottom: 3rem;
}

@media (min-width: 768px) {
    .c-contact {
        display: flex;
        justify-content: left;
        align-items: center;
        
        &-form-inputs {
            flex-wrap: nowrap;

            &-left {
                width: 348px;
                margin-right: 1rem;
            }

            &-right {
                width: 348px;
                margin-left: 1rem;
            }
        }
    }
}


.MuiInputBase-multiline {
    padding: 6px 0 6px !important; // original 6px 0 7px
}

.c-home-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: 0.4s ease 0s 1 moveUpHome;
}

.c-home-image-container {
    margin-bottom: 2rem;
    width: 100%;

    * {
        overflow: visible !important;
    }
}

.c-home-text {
    letter-spacing: 0.5px;
    font-family: "Lekton";
    font-size: 1rem;
    margin-bottom: 4rem;
}

@keyframes moveUpHome {
    0% {
        transform: translateY(2%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }   
}
  

@media (min-width: 768px) {
    .c-home-text {
        font-size: 1.25rem;
    }

    .c-home-image-container {
        width: 100%;
        height: 728px;
    }
}

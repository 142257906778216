.c-footer {
    display: flex;
    justify-content: center;

    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;
    
    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > p {
            margin-bottom: 0.8rem;
        }
    }

    &__item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &-info {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1rem;
    }

    &-socials {
        justify-content: center;

        &__item {
            width: 1.5rem;
        }

        & a + a {
            margin-left: 0.5rem;
        }
    }

    & a {
        display: flex;
        justify-content: flex-start;
    }

    &-logo {
        height: 2rem;
    }
}

@media (min-width: 768px) {
    .c-footer {
        &-logo {
            margin-right: 4.6rem;
        }

        &-socials a + a {
            margin-left: 1rem;
        }

        &-info {
            justify-content: center;
        }

        &__item {
            flex-wrap: nowrap;
        }
    }
}

.c-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 8rem;
    
    & img {
        position: relative;
        top: 0;
        box-shadow: 0 18px 36px rgba(0, 0, 0, 0.2);
        margin-bottom: 1rem;
    }
}


@media screen and (min-width: 768px) {
    .c-carousel {
        & img {
            height: 728px;
        }
    }
}

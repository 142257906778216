.c-header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2rem;
    background-color: #ffffff;

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.5rem;
    }

    &-content-mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-links > a + a {
        margin-left: 2rem;
    }

    &-logo {
        height: 2rem;
    }
}

.c-header .c-header-logo {
    width: auto !important;
}

.c-header-content {
    display: none;
}

.c-header-popup-menu {
    width: calc(100% - 2rem);
    padding: 1rem;
    padding-top: 3rem;

    &-title {
        padding-left: 8px;
        margin-bottom: 2rem;
    }
}



@media (min-width: 768px) {
    .c-header {
        margin-bottom: 4rem;
        justify-content: center;
        width: $global-max-width;

        &-content {
            display: flex;
        }

        &-content-mobile {
            display: none;
        }
    }
}

.c-wip {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100vh;

    &-logo {
        height: 64px;
        width: 64px;
        margin-bottom: 2rem;
    }

    &-text {
        width: 100%;
    }
}
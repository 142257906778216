h1, h2, h3, h4, p {
    margin: 0;
}

p {
    font-size: 1rem;
    letter-spacing: 0.02em; // based on Adobe Xd, value / 1000
}

h2 {
    font-family: "Lekton", "sans-serif";
    font-size: 24px;
    font-weight: 400;
    color: $global-grey-40;
    letter-spacing: -0.01em; // based on Adobe Xd, value / 1000
}

h3 {
    font-family: "Lekton", "sans-serif";
    font-size: 20px;
    font-weight: 400;
    color: $global-grey-30;
    letter-spacing: -0.01em; // based on Adobe Xd, value / 1000
}

h4 {
    font-family: "Lekton", "sans-serif";
    font-size: 18px;
    font-weight: 400;
    color: $global-grey-30;
    letter-spacing: -0.01em; // based on Adobe Xd, value / 1000
}


a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }
}

img {
    width: 100%;
}

@media (min-width: 768px) {
    img {
        width: auto;
    }
}
$global-grey-0: #a6a6a6;
$global-grey-10: #999999;
$global-grey-20: #808080;
$global-grey-30: #666666;
$global-grey-40: #333333;
$global-grey-50: #1a1a1a;

$global-mobile-max-width: calc(100% - 32px);
$global-max-width: 100%;

@import "./generic.scss";
@import "./fonts.scss";
@import "./components/header.scss";
@import "./components/home.scss";
@import "./components/footer.scss";
@import "./components/raambekleding.scss";
@import "./components/detail-page.scss";
@import "./components/contact.scss";
@import "./components/portfolio.scss";
@import "./components/wip.scss";
@import "./components/carousel.scss";

body {
  font-family: "Lato", "Arial", "sans-serif";
  font-weight: 200;
  color: $global-grey-30;
}

.o-content-width {
  width: 100%;
}

.o-fade-in {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.c-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: $global-mobile-max-width;
  margin: 1rem 1rem 0;
}

.c-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-bottom: 4rem;
}

.c-image-placeholder {
  height: 728px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-border-grey {
  border: 1px solid #a6a6a6;
}


@media (min-width: 768px) {
  .o-content-width {
    width: 56.625rem;

    &-small {
      width: 45rem;
    }

    &-large {
      width: 79.875rem;
    }
  }

  .c-app {
    width: $global-max-width;
    margin: 0;
  }

  .c-body {
    width: $global-max-width;
  }
}

.c-portfolio {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 196px);

    &-images {
        flex-grow: 1;
    }

    &-navigation {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;

        width: 12rem;
    }

    &-logo {
        width: 1.5rem;
        height: 1.5rem;

        &-flipped {
            transform: scale(-1, 1);
        }
    }
}
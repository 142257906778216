.c-raambekleding {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    animation: 0.5s ease 0s 1 fadeIn;

    & a + a {
        margin-top: 1.5rem;
    }

    & a {
        width: 282px;
        height: 282px;
        border: 2px solid white;
        box-shadow: none;
        transition: box-shadow 0.33s ease;

        &:hover {
            box-shadow: 0 24px 72px rgba(0, 0, 0, 0.54);
            transition: box-shadow 0.33s ease;
        }
    }

    &-image {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.24);
        width: 100%;
        height: 100%;
    }

    &-details {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 1rem;
        width: calc(282px - 2rem);
        height: calc(282px - 2rem);
        opacity: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
        transition: opacity 0.33s ease;

        & p {
            color: white;
            font-size: 1rem;
            font-weight: 200;
            letter-spacing: 0.025em;
        }
         
        &:hover {
            opacity: 1;
            transition: opacity 0.33s ease;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

@media (min-width: 768px) {
    .c-raambekleding {
        justify-content: space-between;

        & a + a {
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
    }
}

.c-detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-flipped {
        flex-direction: row-reverse;
    }

    &-back-btn {
        display: flex;
        align-items: center;
        padding-bottom: 0.25rem;
    }

    & img {
        border: solid 2px white;
    }

    &-mb-sm {
        margin-bottom: 1.5rem;
    }

    &-mb {
        margin-bottom: 0.5rem;

        &-large {
            margin-bottom: 1.5rem;
        }
    }

    &-title-container {
        display: flex;
        flex-wrap: wrap;

        & a {
            margin-right: 1.2rem;
        }
    }

    &-left {
        width: 100%;
        animation: 0.3s ease 0s 1 moveUpLeft;

        &-img {
            width: calc(100% - 4px);
            height: 31rem;
            margin-bottom: 1rem;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.24);
        }

        &-landscape {
            margin-top: 1.5rem;
        }
    }

    &-right {
        width: 100%;
        animation: 0.5s ease 0s 1 moveUpLeft;

        &-img {
            width: calc(100% - 4px);
            margin-bottom: 1.5rem;
            box-shadow: 0 9px 18px rgba(0, 0, 0, 0.16);
        }

        &-landscape {
            height: auto;
        }
    }
}

@keyframes moveUpLeft {
    0% {
        transform: translateY(5%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }   
}

@keyframes moveUpRight {
    0% {
        transform: translateY(5%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }   
}

@media (min-width: 768px) {
    .c-detail {
        flex-wrap: nowrap;

        &-mb-sm {
            margin-bottom: 4.5rem;
        }

        &-right {
            width: 534px;

            &-landscape {
                margin-top: 3.5rem;
                height: auto;
            }
        }

        &-left {
            width: 348px;
            margin-top: 3rem; // should be 196px, possibly because of longer text
        }
    }
}
